import React from "react"
import moment from "moment"
import "moment/locale/de"
import time_icon from "../../static/images/icons/time.png"
import showdown from 'showdown'

moment.locale('de');

class PostTemplateDetails extends React.Component {
    render() {
        const post = this.props.data.markdownRemark;
        const timeToRead = post.timeToRead;
        const image = post.frontmatter.image.childImageSharp.resize.src;
        const title = post.frontmatter.title;
        const date = post.frontmatter.date;
        const abstract = post.frontmatter.abstract;
        const imageSource = post.frontmatter.imagesource;

        const converter = new showdown.Converter();

        let articleImageStyle = {
            backgroundImage: 'url(' + image + ')',
        };

        return (
            <div>
                <div style={articleImageStyle} className="Article-image"> </div>
                <div className="Article-container">
                    <p className="Article-details">
                        <small className="Article-date">{moment(date).format("D. MMMM YYYY")}</small>
                        <small>
                            Lesezeit: {timeToRead} Min
                            <img src={time_icon} alt="Lesezeit" className="Article-time-icon"/>
                        </small>
                    </p>
                    <h1 className="Article-title">
                        {title}
                    </h1>
                    <div className="Article-abstract"
                         dangerouslySetInnerHTML={{ __html: converter.makeHtml(abstract)}}/>

                    <div className="Article-content"
                         dangerouslySetInnerHTML={{__html: post.html}}/>
                    <small>{imageSource}</small>
                </div>
            </div>
        );
    }
}

export default PostTemplateDetails;