import React from 'react';
import Helmet from 'react-helmet';
import ArticleTemplateDetails from '../components/ArticleTemplateDetails';
import Layout from '../components/Layout';
import {graphql} from 'gatsby';
import PostPreview from "../components/PostPreview";

class ArticleTemplate extends React.Component {
    render() {
        const {title, subtitle} = this.props.data.site.siteMetadata;
        const post = this.props.data.markdownRemark;
        const {title: postTitle, description: postDescription} = post.frontmatter;
        const description = postDescription !== null ? postDescription : subtitle;

        const morePosts = [];
        const allPosts = this.props.data.allMarkdownRemark.edges;
        allPosts.forEach((post) => {
            if (post.node.frontmatter.title !== postTitle) {
                morePosts.push(<PostPreview data={post} key={post.node.frontmatter.path}/>);
            }
        });

        return (
            <Layout>
                <div>
                    <Helmet>
                        <title>{`${postTitle} - ${title}`}</title>
                        <meta name="description" content={description}/>
                    </Helmet>
                    <ArticleTemplateDetails {...this.props} />
                    <hr className="Article-line"/>
                    <div className="ReadMore-container">
                        <h3>Mehr zu dem Thema</h3>
                        <div className="ReadMore-grid">
                            {morePosts}
                        </div>
                    </div>

                </div>
            </Layout>
        );
    }
}

export default ArticleTemplate;

export const pageQuery = graphql`
    query PostQuery($path: String!) {
      markdownRemark(frontmatter: { path: { eq: $path } }) {
        id
        html
        timeToRead
        frontmatter {
            date
            path
            title
            category
            abstract
            imagesource
            image {
            childImageSharp {
                resize(quality: 100, width: 800) {
                    src
                }
            }
          }
            description
        }
      }
        site {
            siteMetadata {
                description
                title
            }
        }
    allMarkdownRemark(sort: {order: DESC, fields: frontmatter___date}, limit: 3) {
    edges {
      node {
        timeToRead
        frontmatter {
          title
          description
          image {
            childImageSharp {
                resize(quality: 100, width: 800) {
                    src
                }
            }
          }
          path
          category
          date
        }
      }
    }
  }
}
`;